import React, { createContext, useState, useEffect} from 'react';
import {firebase} from '../firestore/config';
import 'firebase/auth';
export const userContext = createContext({initializing: true, user: null});

export const UserProvider = (props) => {

    const [auth, setAuth] = useState(() => {
      const user = firebase.auth().currentUser;
      return {initializing: !user, user}
    });

  

    
    useEffect(() => {
      
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        const token = user ? (user.getIdToken()): (null)
        setAuth({
          user,
          initializing: false,
          token
        })
      });
      return () => unsubscribe()
    }, [])


    return(
      <userContext.Provider value={auth}>
          {props.children}
      </userContext.Provider>
    )
}


export const createUser = (email, password, confirm) => {
  if(password === confirm){
    firebase.auth().createUserWithEmailAndPassword(email, password)
    .catch(err => err.message);
  }else{
    return 'Passwords dont match'
  }
}

export const login = async ( user, password) => {
      return new Promise((resolve, reject ) => {
        firebase.auth().signInWithEmailAndPassword(user, password)
        .then(()=> {
          resolve()
        })
        .catch(err =>  reject(err))
      })

}

export const signOut = async ( ) => {
  firebase.auth().signOut();
}