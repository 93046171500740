import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import moment from 'moment'
import { client } from '../axios/methods';


export const timeContext = createContext(null);

export const TimeProvider = (props) => {

    const [time, setTime] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(moment().startOf('isoweek'));
    const [updated, setUpdated] = useState(false)
    const [loadedDates, setLoadedDates] = useState([])
    






    // Accepts the array and key
    const groupBy = (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
        }, {}); // empty object is the initial value for result object
    };


    


    // const getTime = () => {
    //     return new Promise((resolve, reject) => {
    //         setLoading(true)
    //         axios.get(`http://localhost:8101/hours?fromDate=${moment(date).format('YYYY-MM-DD')}&toDate=${moment(date).days(7).hour(4).format('YYYY-MM-DD HH:mm:ss')}`)
    //         .then((res) => {
    //              const keys = Object.keys(res.data);
    //              const data = Object.values(res.data).map((obj, i) => ({[keys[i]] : groupBy(obj, "projectID")}))

                 
    //             resolve(data)
    //         }).catch(( err ) => {
    //             reject(err)
    //         })
    //     })

    // }

    const updateHours = async () => {

            try{
                setLoading(true)
                const response = await client.post('/hours', time);
                setUpdated(!updated);
                setLoading(false)
                return response;

            }catch(err){
            
              setError(err.message)
              setLoading(false)
            }       

    }

    const mergeData = () => {

    }




    useEffect(() => {
  
        console.log(loadedDates)
        setLoadedDates([...loadedDates, date.format('YYYY-MM-DD')])
        setLoading(true)
        axios.get(`https://arxvitrum-279211.oa.r.appspot.com/hours?fromDate=${moment(date).format('YYYY-MM-DD')}&toDate=${moment(date).days(7).hour(4).format('YYYY-MM-DD HH:mm:ss')}`)
        .then((res) => {
        
             const keys = Object.keys(res.data);
             const data = Object.values(res.data).map((obj, i) => ({[keys[i]] : groupBy(obj, "projectID")}))
             
            return data;
        }).then((data) => {
            setTime([])
            console.log(data)
            setLoading(false)
            setTime([...data]);

            
            
        }).catch(err => {
            setError(err.message);
            setLoading(false);
            setTime("");
        })
    
    }, [date, updated])


    return(
        <timeContext.Provider value={{time, timeErr: error, loading, setTime, date, setDate, setLoading, updateHours}}>
            {props.children}
        </timeContext.Provider>
    )
}



