import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {makeStyles} from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress';
import {client} from '../../../axios/methods';
const filter = createFilterOptions();

const styles = ( theme ) =>({
    backdropStyle: {
        background: 'rgba(0,0,0,0.2)'
    }
})
const useStyles = makeStyles(styles)

// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }

export const Form = ({setTime, handleClose, handleClickOpen, time, open, id, setOpen, token}) => {

  const classes = useStyles()
  const [projectid, setProjectid] = useState('')
  // const [projectName, setProjectname] = useState('');
  // console.log(projectName)

  const [options, setOptions] = React.useState([]);
  const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
  const loading = autoCompleteOpen && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await client({
        method: 'get',
        url: '/projects',
        headers:{
          AuthToken: token.i
        }
      })
      // await sleep(1e3); // For demo purposes.
      const projects = response.data;

      if (active) {
        setOptions(projects.map((project) => project));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, token.i]);

  React.useEffect(() => {
    if (!autoCompleteOpen) {
      setOptions([]);
    }
  }, [autoCompleteOpen]);

  const addLine = async (e) => {
    e.preventDefault()

    const isId = time.some((element) => parseInt(Object.keys(element)[0]) === parseInt(id) )
  
    isId ? setTime(time.map(obj => {
        if(parseInt(Object.keys(obj)) === parseInt(id)){
          return{
            ...obj,
            [id]: {
              ...obj[id],
              [projectid]: []
            }
          }
        }else{
            return{
                ...obj
            }
        }
        }))
        : 
        setTime([
        ...time,
        {[id]: {
            [projectid]: []
        }}
        
    ])

  
    setOpen(false);
  }

  return (
    <div>
 
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" BackdropProps={{
          classes:{
              root: classes.backdropStyle
          }
      }}>
            <form onSubmit={addLine}>
        <DialogTitle id="form-dialog-title">Create New Line</DialogTitle>
     
        <DialogContent>
          <DialogContentText>
            Enter the following to make a new line
          </DialogContentText>
          {/* <Autocomplete
        
        id="free-solo-2-demo"
        disableClearable
        options={time.map((obj) => Object.keys(obj))}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search input"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
            autoFocus
            margin="dense"
            name="projectID"
            label="Project ID"
            type="text"
            fullWidth
            onChange={(e) => {setProjectid(e.currentTarget.value)}}
        />
        )}
      /> */}
      <Autocomplete
    
      freeSolo
      style={{ width: 300 }}
      open={autoCompleteOpen}
      onChange={(e, value) => {
 
        if(value){
        setProjectid(value.nummer)
        // setProjectname(value.sales_name)
      }
        else{
          setProjectid("")
          // setProjectname("")
        }
      }}
      onOpen={() => {
        setAutoCompleteOpen(true);
      }}
      onClose={() => {
        setAutoCompleteOpen(false);
      }}
      getOptionSelected={(option, value) => {

        return option.name === value.beskrivelse}}

      getOptionLabel={(option) => {
        
        if (typeof option === 'string') {
          return `${option.nummer}: ${option.beskrivelse}`;
        }
        if (option.inputValue) {
          return option.inputValue;
        }

        return `${option.nummer}: ${option.beskrivelse}`
        }
      
      }


      filterOptions={(options, params) => {
        const filtered = filter(options, params);
   
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            "nummer": `${params.inputValue}`
          });
        }

        return filtered;
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          name="projectID"
          label="Project ID"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Create Line
          </Button>
        </DialogActions>
        </form>
      </Dialog>
 
    </div>
  );
}