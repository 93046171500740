import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import { client } from '../axios/methods';

export const profilesContext = createContext(null);

export const ProfilesProvider = (props) => {

    const [profiles, setProfiles] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    


    const getProfiles = () => {
        return new Promise((resolve, reject) => {
            client.get('/users')
            .then((res) => {
                
                resolve(res.data)
            }).catch(( err ) => {
                reject(err)
            })
        })

    }

    useEffect(() => {
        
        getProfiles()
        .then((data)=> {

            setProfiles(data)
 
        }).catch(err => {
            setError(err)
            setLoading(true);
        })
        return () => getProfiles()
        
    }, [])


    return (
        <profilesContext.Provider value={{profiles, error, loading, setProfiles}}>
            {props.children}
        </profilesContext.Provider>
    )
}


