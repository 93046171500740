import React, {useContext} from 'react';
import {v4 as uuid} from 'uuid';
import {Header} from '../../../components/partials/Header';
import {profilesContext} from '../../../context/profilesContext';
import {updateUsers} from '../../../axios/methods';
import {userContext} from '../../../context/userContext';

//MUICOMPONENTS

import TextField from '@material-ui/core/TextField';
import {makeStyles, Button} from '@material-ui/core/'

const useStyles = makeStyles((theme)=> ({
    employeeBox: {
        margin: "30px",
        display: "block",
        border: "2px solid",
        minHeight: "250px",
        padding: "10px"
    },
    inputFields: {
        margin: "25px"
    },
    submitButton: {
        float: "right",
        margin:"50px 25px 25px 0px",
        '&:after': {
            content: '""',
            clear: "both"
        }
    },
    addRow: {
        float: "right",
        margin: "25px",
        '&:after': {
            content: '""',
            clear: "both"
        }
    }
}))



export const Employees = () => {


    const {profiles, setProfiles} = useContext(profilesContext);
    const {token} = useContext(userContext)
    const classes = useStyles();


    const onChange = (e) =>  {
        const field = e.target.name;
        const value = e.target.value;
        const formId = e.currentTarget.getAttribute('id');


        setProfiles([...profiles].map(object => {
            if(object.uuid === formId){
            return{
                ...object, 
                [field]: value }
        }else{
            return object
        }
        }))

  
        
    }

    const onSubmitForm  = async(e) => {
        e.preventDefault();
        try{
        const form = e.target.id;
        const inputData = profiles.filter((object) => {
            if(object.uuid !== form){
                return false;
            }
            return true;
        })
        
        const res = await updateUsers(inputData, token.i)
        alert(res)
        }catch(err){
            alert(err)
        }
    }

    const newRow = () => {
        setProfiles([
            ...profiles,
            {uuid: uuid(), firstName: "", lastName:"", salaryLT:"", taxPercentage:"", taxDeduction:"", email:""}
        ]
        )
       
    }



    const toggleActive = async (e) => {
        e.preventDefault();

        try{
        const id = e.currentTarget.id;
    
        let status = profiles.filter((profile)=> {
                if(parseInt(profile.id) !== parseInt(id)){
                    return false
                }
                return true;
        }).map(obj => ({id: obj.id, active: obj.active}));

        const isActive = status[0]

        const realStatus = {
            id: isActive.id,
            active: parseInt(isActive.active) === 1 ? (0) : (1)
        }
        
        await updateUsers([realStatus])
       

        setProfiles([...profiles].map(object => {
            if(parseInt(object.id) === parseInt(isActive.id)){
             
            return{
                ...object, 
                active: parseInt(isActive.active) === 1 ? (0) : (1) }

        }else{
            return object
        }
        }))

 


    }catch(err){
        alert(err.message)
    }
    }





    const html = profiles.map((profile)=> {
        return(
        <form key={profile.uuid} id={profile.uuid} className={classes.employeeBox} onChange={onChange} onSubmit={onSubmitForm}>
           
            <TextField className={classes.inputFields} name="firstName" label="First Name" variant="filled" defaultValue={profile.firstName} />
            <TextField className={classes.inputFields} name="lastName" label="Last Name" variant="filled" defaultValue={profile.lastName} />
            <TextField className={classes.inputFields} name="email" label="E-mail" variant="filled" defaultValue={profile.email} />
            <TextField className={classes.inputFields} name="salaryLT" label="Salary" variant="filled" defaultValue={profile.salaryLT} />
            <TextField className={classes.inputFields} name="dkSalary" label="dkSalary" variant="filled" defaultValue={profile.dkSalary} />
            <TextField className={classes.inputFields} name="taxPercentage" label="Tax Percentage" variant="filled" defaultValue={profile.taxPercentage} />
            <TextField className={classes.inputFields} name="taxDeduction" label="Tax Deductions" variant="filled" defaultValue={profile.taxDeduction} />
            <TextField className={classes.inputFields} disabled name="Active" label="Status" variant="filled" value={parseInt(profile.active) === 1 ? ('Active'):('Inactive')} />

            
            <Button type="submit" variant="outlined" className={classes.submitButton}>Submit</Button>
            {parseInt(profile.active) === 1 ? (<Button variant="outlined" onClick={toggleActive} className={classes.submitButton} id={`${profile.id}`} color="secondary">Deactivate</Button>):
            (<Button variant="outlined" onClick={toggleActive} className={classes.submitButton} id={`${profile.id}`} color="primary">Activate</Button>)
            
        }

        </form>
        )
    })
    return (
        <div>
            <Header/>
            <div id="formRows">
                {html.map(element => element)}
            </div>
            
            <div className={classes.addRow}>
                <Button variant="outlined" size="large" onClick={newRow}>New</Button>
            </div>
        </div>
    )
}

