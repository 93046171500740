import axios from 'axios';

export const client = axios.create({
    baseURL: 'https://arxvitrum-279211.oa.r.appspot.com'
})

export const updateUsers = (data, token) => {
    return new Promise(async(resolve, reject) => {
        try{

            const response = await client({
                method: 'POST',
                url: '/users',
                headers:{
                  AuthToken: token
                },
                data: data[0]
              })
            resolve(response)
        }catch(err){
            reject(err)
        }
    })
}

export const updateHours = (data) => {
    return new Promise(async(resolve, reject) => {
        try{

            const response = await client.post('https://arxvitrum-279211.oa.r.appspot.com/hours', data);
            resolve(response)

        }catch(err){
            console.log(err)
            reject(err.message)
        }       
    })
}
