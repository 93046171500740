import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const LoginForm = ({open, setOpen, submitLogin}) =>  {


const [login, setLogin] = useState({
    email: "",
    password: ""
})

  const handleClose = () => {
    setOpen(false);
  };

 const changeHandler = (e) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value
    
    setLogin({
      ...login,
      [field]: value
    })
   
 }

 const submitHandler = async() => {
   try{
    submitLogin(login.email, login.password);
   }catch(err){
     console.log(err)
   }
  
 }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Please enter your credentiels to login
          </DialogContentText>
          <form>
          <TextField
            autoFocus
            onChange={changeHandler}
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
          />
            <TextField
            
            margin="dense"
            onChange={changeHandler}
            name="password"
            label="Password"
            type="password"
            fullWidth
          />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submitHandler} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}