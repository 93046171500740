import React, {useContext, useState} from 'react';
import {NavLink}  from 'react-router-dom';
import {userContext} from '../../context/userContext';
import {signOut} from '../../context/userContext';
import {LoginForm} from './LoginForm';

import {login} from '../../context/userContext';


import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Register } from '../../views/viewComponents/Register/Register';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  navBtn:{
    color: "#ffffff",
    textDecoration: "none"
  }
}));




export const Header = () =>  {
  const classes = useStyles();
const [open, setOpen] = React.useState(false);

const [error, setError] = useState(false)

const submitLogin = async (email, password ) => {
  try{
  const resp = await login(email, password)
  
  setError(false)
}catch(err) {
  setError(err.message)
}
}

const handleClickOpen = () => {
  setOpen(true);
};

const submitSignout = () => {
  signOut();
}

const {user} = useContext(userContext);

  return (
    
    <div className={classes.root}>
      
      <AppBar position="static">
        <Toolbar>

          <Typography variant="h6" className={classes.title}>
            Alutime
          </Typography>
          {!user ? (<Button color="inherit" onClick={handleClickOpen}>Login</Button>) : (
          <React.Fragment>
          <NavLink to="/" className={classes.navBtn}><Button color="inherit" >Dashboard</Button></NavLink >
          <NavLink to="/employees" className={classes.navBtn}><Button color="inherit" >Employees</Button></NavLink >
          {user.email === 'marc@aluflam.dk' ? (<NavLink to="/controlpanel" className={classes.navBtn}><Button className={classes.navBtn} color="inherit" >Controlpanel</Button></NavLink> ) : ("")}
            <Button color="inherit" onClick={submitSignout}>Log out</Button> 
            {user.email === 'marc@aluflam.dk' ? (<Register /> ) : ("")}
            
          {error ? (<div><h2>{error}</h2></div>): ""}
            
          
          </React.Fragment>
          
          
          
          )}
        </Toolbar>
      </AppBar>
     <LoginForm open={open} setOpen={setOpen} submitLogin={submitLogin} />
    </div>
  );
}