import React, {useContext, useState} from 'react';
import {timeContext} from '../../../context/timeContext';
import {userContext} from '../../../context/userContext';
import moment from 'moment';


//MUI
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { IconButton } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';
import {Form} from './Form';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
   
    
  },
  tableTop:{
    margin: "20px 5px 50px 5px",
    position: "relative"

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  btn: {
    position: "absolute",
    right: "calc(50% - 24px)",
    bottom: "-50px",
    zIndex: "1"
  }
}));



export const TimeTable = ({profiles, date, user}) => {
  const {token} = useContext(userContext);

  const classes = useStyles();
  const {time, setTime} = useContext(timeContext);

  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null)

  const handleClickOpen = (e) => {
    setId(e.currentTarget.id)
    setOpen(true);
  };

  const handleClose = () => {
    setId(null)
    setOpen(false);
  };
const activeProfiles = profiles.filter((profile) => {
  if(parseInt(profile.active)  == 1 ){
    return true
  }else{
    return false
  }
})




  const dateArray = () => {
    var dateArray = [];
    for(let i = 1; i <= 7; i++){
      dateArray.push(moment(date).day(i).format('YYYY-MM-DD HH:mm:ss'))
    }
    return dateArray;
  }


 const handleChange = (e) => {
   const params = e.currentTarget.id.split('_');
   const value = e.currentTarget.value;
 
   

    setTime(time.map((obj) => {
      if(Object.keys(obj)[0] === params[0]){
        return{
          ...obj,
          [params[0]]: {
            ...obj[params[0]],
            [params[1]]: 
            obj[params[0]][params[1]].some(date => moment(date.date).format('YYYY-MM-DD') ===  moment(params[2]).format('YYYY-MM-DD')) ? 
              obj[params[0]][params[1]].map((obj) => {
                if(moment(obj.date).format('YYYY-MM-DD') === moment(params[2]).format('YYYY-MM-DD')){
                  
                  return{
                    ...obj,
                    hours: value,
                    date: moment(obj.date).format('YYYY-MM-DD HH:mm:ss')
                  }
                }else{
                 
                  return{
                    ...obj,
                    date: moment(obj.date).format('YYYY-MM-DD HH:mm:ss')
                  }
                }

            })
            :
            [...obj[params[0]][params[1]],
              {
              userID: params[0],
              projectID: params[1],
              date: moment(params[2]).format('YYYY-MM-DD HH:mm:ss'),
              hours: value,
              createdBy: user.email
            }
          ]

          }
        }
      }else{
        return obj
      }
    }))

 }
 
//  useEffect(() =>{
//     console.log(time)
//  },[time])


return activeProfiles.map((profile) => {
  return(
<div className={classes.tableTop} key={profile.uuid}>
<TableContainer  component={Paper} >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{profile.firstName}</TableCell>
            <TableCell align="center">Monday</TableCell>
            <TableCell align="center">Tuesday</TableCell>
            <TableCell align="center">Wednesday</TableCell>
            <TableCell align="center">Thursday</TableCell>
            <TableCell align="center">Friday</TableCell>
            <TableCell align="center">Saturday</TableCell>
            <TableCell align="center">Sunday</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        {Object.values(time).map((timeObj) => {
        
        const users = Object.keys(timeObj).map((userId) => {
          

          if(parseInt(userId) === parseInt(profile.id)){


            return Object.keys(timeObj[userId]).map((projectKey) => {
                if(!projectKey) return
                return(
                      <React.Fragment key={projectKey}>
                        <TableRow >
                        <TableCell component="th" scope="row">
                        <TextField
                            label="ProjectID"
                            name="projectID"
                            defaultValue={projectKey}
                            variant="outlined"
                            id={profile.id+"_"+projectKey}
                            size="small"
                            disabled
                        />
                          </TableCell>
                          {dateArray().map((dates, i) => {
                            // return timeObj[userId][projectKey].map((value) => {
                            //   if(dates == moment(value.date).format('YYYY-MM-DD')){
                              const data = timeObj[userId][projectKey].map((val) => {
                                if(moment(dates).format('YYYY-MM-DD') === moment(val.date).format('YYYY-MM-DD')){
                                  // console.log(val)
                                  // console.log(`${val.userID}`)
                                  // console.log(`${moment(dates).format('YYYY-MM-DD')} vs. ${moment(val.date).format('YYYY-MM-DD')} - CHECKED`)
                                  return {date: moment(val.date).format('YYYY-MM-DD'), hours: val.hours}
                                }else{
                                  // console.log(val.date)
                                  // console.log(`${val.userID}:`)
                                  // console.log(`${moment(dates).format('YYYY-MM-DD')} vs. ${moment(val.date).format('YYYY-MM-DD')}`)
                                  return 0;
                                }
                              }).filter((res) => {
                                if(res === 0){
                                  return false
                                }else{
                                  return true
                                }
                              })
                            
                              return(
                                <TableCell align="right" key={dates} id={dates}>

                                 <TextField
                                     label="Hours"
                                     name={moment(dates).format('YYYY-MM-DD')}
                                     defaultValue={data.map((obj) => {
                                      if(moment(obj.date).format('YYYY-MM-DD') === moment(dates).format('YYYY-MM-DD')){
                                        return obj.hours
                                      }else{
                                        return 0
                                      }
                                     })}
                                     variant="outlined"
                                     onChange={handleChange}
                                     id={profile.id+"_"+projectKey+"_"+moment(dates).hour(2).format('YYYY-MM-DD HH:mm:ss')}
                                     size="small"
                                 />
                       
                            
                               
                                
                                 </TableCell>
                                 
                                  )
                          
                          })}
                          </TableRow>
 
                          </React.Fragment>



                    )

              })

          }
        })

        return users
        
    })}

        </TableBody>
        
      </Table>
      <IconButton color="primary" id={profile.id} onClick={handleClickOpen} className={classes.btn}><Add /></IconButton>
    </TableContainer>
    <Form setTime={setTime} handleClickOpen={handleClickOpen} handleClose={handleClose} time={time} open={open} id={id} setOpen={setOpen} token={token} />
    </div>

  )


})
    
   
    
  
}