import React from 'react';
import {Dashboard} from './views/Dashboard'
import {Employees} from './views/viewComponents/employees/Employees';
import {Controlpanel} from './views/Controlpanel';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import DateFnsUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {ProfilesProvider} from './context/profilesContext';
import {UserProvider} from './context/userContext';
import {TimeProvider} from './context/timeContext';




function App() {
  return (
    <div style={{background: "#e3e3e3", overflowX: "hidden"}} className="App">
      <UserProvider>
      <ProfilesProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimeProvider>
            <Router>
              <Switch>
                
                <Route path="/" exact component={Dashboard} />
                
                <Route path="/employees" component={Employees} />

                <Route path="/controlpanel" component={Controlpanel} />
              </Switch>
            </Router>
            </TimeProvider>
            </MuiPickersUtilsProvider>
          </ProfilesProvider>
        </UserProvider>
    </div>
  );
}

export default App;
