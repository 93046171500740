import app from 'firebase/app';
 // Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAm_UJ7goVk5zB17tTSEic2ofryyP0LtHQ",
  authDomain: "timereg-ebf08.firebaseapp.com",
  databaseURL: "https://timereg-ebf08.firebaseio.com",
  projectId: "timereg-ebf08",
  storageBucket: "timereg-ebf08.appspot.com",
  messagingSenderId: "12712266890",
  appId: "1:12712266890:web:49b11aa5241ee63c55f173"
  };

export const firebase = app.initializeApp(firebaseConfig);
