import React from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {makeStyles} from '@material-ui/core'

const style = (theme) => ({
    container: {
        display: "flex",
        minHeight: "50px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row"
    },
    button: {
        
    }, 
    title:{
        display: "inline",
    }

})
const useStyles = makeStyles(style);

export const WeekPicker = ({date, setDate}) => {

    const classes = useStyles();
    console.log(date)
    console.log(moment(date).isoWeek())

    return (
        <div className={classes.container}>
            <IconButton onClick={() => {setDate(moment(date).day(-6))}}><ChevronLeft /></IconButton><h2 className={classes.title}>Week: {moment(date).isoWeek()}</h2><IconButton onClick={() => {setDate(moment(date).add(7, "days"))}}><ChevronRight /></IconButton>
        </div>
    )
}
