import React from 'react';

import {createUser} from '../../../context/userContext';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';




export const Register = () => {


    const [open, setOpen] = React.useState(false);

    const [register, setRegister] = React.useState({
        email: "",
        password: "",
        confirm:""
    })

    const handleClickOpen = () => {
      setOpen(true);
      
    };
  
    const handleClose = () => {
      setOpen(false);
      
    };
    
    const handleChange = e =>  {
        const value = e.target.value;
        setRegister({
            ...register,
            [e.target.name]: value
        })
       
       
    }

    //Proper error handling
    const handleSubmit = async () => {
        try{
        const response = createUser(register.email, register.password, register.confirm)
        alert(response);
        }catch(err){
            alert(err.message)
        }
        
    }




    return (
        <React.Fragment>
        <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
          Register
        </Button>
        <Dialog open={open}>
        <div>
            <TextField
            autoFocus
            margin="dense"
            id="registerEmail"
            label="Email Address"
            type="email"
            name="email"
            fullWidth
            onChange={handleChange}
            value={register.email}
            />

            <TextField
            margin="dense"
            id="registerPassword"
            label="Password"
            type="password"
            name="password"
            fullWidth
            onChange={handleChange}
            value={register.password}
            />

            <TextField
            margin="dense"
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            name="confirm"
            fullWidth
            onChange={handleChange}
            value={register.confirm}
            />
            </div>
        
        
            <div>
                <Button onClick={handleSubmit} color="primary">
                    Register
                </Button>

                <Button onClick={handleClose} color="primary">
                Cancel
                </Button>
            </div>
        </Dialog>
        </React.Fragment>
    )
}
