import React, {useContext} from 'react';
import {TimeTable} from './viewComponents/table/TimeTable';
import {userContext} from '../context/userContext';
import {profilesContext} from '../context/profilesContext';
import { Typography } from '@material-ui/core';
import CircularProgress  from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core';
import {Header} from '../components/partials/Header';
import { WeekPicker } from './viewComponents/table/WeekPicker';
// import {updateHours} from '../axios/methods';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { timeContext } from '../context/timeContext';

const styles = (theme) => ({
    loading: {
        position: "fixed",
        width: "100%",
        height: "70%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        left: "0",
        center: "0"
    },
    flBtn: {
        position: "fixed",
        right: "50px",
        bottom: "50px",
        zIndex: "99"
    }
})

const useStyles = makeStyles(styles)


export const Dashboard = (props) => {
    const classes = useStyles()

     const {user, initializing, setAuth} = useContext(userContext);
     
     const {error, profiles} = useContext(profilesContext)
     const {setDate, date, loading, updateHours, timeError} = useContext(timeContext)

    //  const saveSchedule = async () => {
    //      try{
    //         setLoading(true);
    //         const response = await updateHours(time)
    //         setLoading(false)
    //         console.log(response)
    //      }catch(err){
    //          setLoading(false);
    //          alert(err)
    //      }
 
    
    //  }
    

    return !initializing ? (user ? ( loading ?    <div className={classes.loading}><CircularProgress  size="250px"/>  </div> :
        <React.Fragment>
            {timeError ? (<div style={{textAlign: "Center"}}><h1>{timeError}</h1></div>):("")}
            <Header />
            <Fab color="secondary" aria-label="add" className={classes.flBtn} onClick={updateHours}>
          <AddIcon />
        </Fab>
            <Typography variant="overline">Logget ind som {user.email}</Typography>
            <WeekPicker date={date} setDate={setDate}></WeekPicker>
         {error ? (<div style={{textAlign: "Center"}}><h1>{error.message}</h1></div>) 
        :
        (<TimeTable profiles={profiles} date={date} setAuth={setAuth} user={user}/>)}



</React.Fragment>) : (   <div>
            <Header/>
            <Typography variant="overline">Ikke logget ind</Typography>
        </div>)

    )
    : 
    (   <div className={classes.loading}><CircularProgress  size="250px"/>  </div>
        
    )

}
