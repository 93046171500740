import React, {useContext, useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import {userContext} from '../context/userContext';
import {profilesContext} from '../context/profilesContext';
import { Typography } from '@material-ui/core';
import CircularProgress  from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core';
import {Header} from '../components/partials/Header';
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import {DatePicker} from '@material-ui/pickers';

// import {updateHours} from '../axios/methods';



import { timeContext } from '../context/timeContext';

const styles = (theme) => ({
    inputGroup: {
        margin: "50px",
        textAlign: "center",

    }
})

const useStyles = makeStyles(styles)


export const Controlpanel = (props) => {
    const classes = useStyles()

     const {user, initializing, setAuth} = useContext(userContext);
     const {error, profiles} = useContext(profilesContext)
     const {setDate, date, loading, updateHours, timeError} = useContext(timeContext)
     const [fromDate, handleFromDateChange] = useState(new Date());
     const [toDate, handletoDateChange] = useState(new Date());
     const [weeks, setWeeks] = useState(moment().isoWeeks().toString())

     const sendNgrok = () => {
         axios.get("https://aluflamserver.ew.r.appspot.com/ngrok")
     }

     const makeInvoiceOverview = () => {
         axios.get(`https://aluflamserver.ew.r.appspot.com/projecttime?fromDate=${moment(fromDate).format('YYYY-MM-DD')}&toDate=${moment(toDate).format('YYYY-MM-DD')}`)
     }

     const sendRapportForManagers = () => {
        axios.get(`https://aluflamserver.ew.r.appspot.com/projectHours?fromDate=${moment(fromDate).format('YYYY-MM-DD')}&toDate=${moment(toDate).format('YYYY-MM-DD')}`)
     }

     const testConnection = () => {
        axios.get(`https://aluflamserver.ew.r.appspot.com/`)
     }
    
     const makeSalary = async () => {
        axios.get(`https://aluflamserver.ew.r.appspot.com/weeklytime?week=${weeks}`).then((html) => {

            console.log(html)
    
            
        })
     }

     const onChangeHandler = (e) => {
         const value = e.currentTarget.value;
         console.log(value)
         console.log(weeks)
         setWeeks(value)
     }

    return !initializing ? (user ? ( loading ?    <div className={classes.loading}><CircularProgress  size="250px"/>  </div> :
        <React.Fragment>
            <Header />

         {error ? (<div style={{textAlign: "Center"}}><h1>{error.message}</h1></div>) 
        :
        ("")}
            <Container fixed>
                <div className={classes.inputGroup}>
                <DatePicker value={fromDate} onChange={handleFromDateChange} format="DD/MM/yyyy"/>
                <DatePicker value={toDate} onChange={handletoDateChange} format="DD/MM/yyyy"/>

                </div>
                <div>
                    <Button variant="outlined" onClick={sendNgrok}>NGROK</Button>
                    <Button variant="outlined" color="primary" onClick={makeInvoiceOverview}>
                        Sagsoversigt
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={sendRapportForManagers}>
                        Projektoversigt til managers
                    </Button>
                    <Button variant="outlined" onClick={testConnection}>
                        Test
                    </Button>
                    <div className={classes.inputGroup}>
                    <TextField variant="outlined" name="Weeks" onChange={onChangeHandler} value={weeks}/>
                    <Button variant="outlined" onClick={makeSalary}>
                        Submit
                    </Button>
                    </div>
                </div>

            </Container>
   



</React.Fragment>) : (   <div>
            <Header/>
            <Typography variant="overline">Ikke logget ind</Typography>
        </div>)

    )
    : 
    (   <div className={classes.loading}><CircularProgress  size="250px"/>  </div>
        
    )

}
